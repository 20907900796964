import { HeaderViewController } from '@/views/quick_punch/header/HeaderViewController';
import { QuickPunchLoginViewController } from '@/views/quick_punch/login/QuickPunchLoginViewController';
import { QuickPunchViewController } from '@/views/quick_punch/punch/QuickPunchViewController';
import '@/theme/default/css/quickPunch.css';

export class ApplicationRouter extends Backbone.Router {
	constructor( options = {} ) {
		_.defaults( options, {
			controller: null,
			headerView: null,
			routes: {
				'': 'onViewChange',
				'!:viewName': 'onViewChange',
				'*notFound': 'notFound'
			}
		} );

		super( options );
	}

	loadView( view_id ) {
		Global.loadViewSource( view_id, view_id + 'View.html', function( result ) {
			var template = _.template( result );
			Global.contentContainer().html( template );

			LocalCacheData.current_open_view_id = view_id;

			Global.trackView( view_id );
		} );
	}

	reloadView( view_id ) {
		//error: Uncaught ReferenceError: XXXXViewController is not defined ininterface/html5/#!m=TimeSheet line 3
		// Happens when quickly click on context menu and network is slow.
		if ( typeof window[view_id] === 'function' ) { //Was ES5: window[view_id + 'ViewController'] &&
			this.loadView( view_id );
		}
	}

	notFound( url ) {
		var new_url = Global.getBaseURL();

		Global.setURLToBrowser( new_url + '#!m=QuickPunchLogin' );
	}

	/* jshint ignore:start */
	onViewChange( viewName ) {
		var $this = this;
		var args = {};
		var view_id;

		if ( Global.needReloadBrowser ) {
			Global.needReloadBrowser = false;
			window.location.reload();
			return;
		}

		if ( viewName ) {
			args = Global.buildArgDic( viewName.split( '&' ) );
		}
		if ( viewName && viewName.indexOf( 'm=' ) >= 0 && viewName.indexOf( 'QuickPunch' ) >= 0 ) {
			view_id = Global.sanitizeViewId( args.m );
		} else {
			view_id = 'QuickPunchLogin';
			Global.setURLToBrowser( Global.getBaseURL() + '#!m=QuickPunchLogin' );
		}

		LocalCacheData.fullUrlParameterStr = viewName;

		LocalCacheData.setAllURLArgs( args );
		var reg = new RegExp( '^[0-9]*$' );
		var timeout_count;
		timeout_count = 0;
		// $('link[title="application css"]').prop('disabled', true);
		if ( LocalCacheData.loadViewRequiredJSReady ) {
			showRibbonMenuAndLoadView();
		} else {
			var auto_login_timer = setInterval( function() {
				if ( timeout_count == 100 ) {
					clearInterval( auto_login_timer );
				}
				timeout_count = timeout_count + 1;
				if ( LocalCacheData.loadViewRequiredJSReady ) {
					showRibbonMenuAndLoadView();
					clearInterval( auto_login_timer );
				}
			}, 600 );
		}

		function showRibbonMenuAndLoadView() {
			$( 'body' ).removeClass( 'login-bg' );
			// Global.loadStyleSheet( '../theme/default/css/quickPunch.css' + '?v=' + APIGlobal.pre_login_data.application_build ); // #2844 quickpunch css will now be loaded at start.
			if ( !$this.headerView ) {
				$this.headerView = new HeaderViewController();
				$( '#topContainer' ).html( $this.headerView.el );
			}
			loadViewController();
		}

		function loadViewController() {
			//Hard code all view controllers as there is only two. This avoids Vite's dynamic imports trying to import everything into the bundles.
			switch ( view_id ) {
				case 'QuickPunchLogin':
					var view_controller = new QuickPunchLoginViewController();
					break;
				case 'QuickPunch':
					var view_controller = new QuickPunchViewController();
					break;
			}

			Global.trackView( view_id );
			/* jshint ignore:end */

			// Only show the top and bottom containers until after the view html is loaded, otherwise the download app links flash up at the top of the page, where the login header should be.
			// TODO: Improve this with Vue to be smoother and more structured, rather than trial and error.
			Global.topContainer().css( 'display', 'block' );
			Global.bottomContainer().css( 'display', 'block' );
		}
	}

	/* jshint ignore:end */

	cleanAnySubViewUI() {
		var children = Global.contentContainer().children();

		if ( children.length > 1 ) {
			for ( var i = 1; i < children.length; i++ ) {
				// Object doesn't support property or method 'remove', Not sure why, add try catch to ingore this error since this should no harm
				try {

					if ( $( children[i] ).attr( 'id' ) === LocalCacheData.current_open_primary_controller.ui_id ) {
						continue;
					} else {
						children[i].remove();
					}

				} catch ( e ) {
					//Do nothing
				}

			}
		}
	}

	setContentDivHeight() {
		Global.contentContainer().removeClass( 'content-container' );
		Global.contentContainer().addClass( 'content-container-after-login' );
		Global.topContainer().addClass( 'top-container-after-login' );

	}

	addTopMenu() {
		Global.loadScript( 'global/widgets/top_menu/TopMenuController.js' );
		if ( TopMenuController ) {
			TopMenuController.loadView();
		}

	}

	removeCurrentView( callBack ) {
		if ( LocalCacheData.current_open_edit_only_controller ) {
			clean( LocalCacheData.current_open_edit_only_controller );
			LocalCacheData.current_open_edit_only_controller = null;
		}

		if ( LocalCacheData.current_open_primary_controller ) {
			if ( LocalCacheData.current_open_primary_controller.edit_view ) {
				clean( LocalCacheData.current_open_primary_controller );
			}
			Global.contentContainer().empty();
			LocalCacheData.current_open_primary_controller.cleanWhenUnloadView( callBack );
		} else {

			if ( Global.isSet( callBack ) ) {
				callBack();
			}
		}

		function clean( viewController ) {
			viewController.clearErrorTips();
			// Cannot read property 'remove' of null in interface/html5/IndexController.js?v=9.0.0-20151016-153057 line 439
			if ( viewController.edit_view ) {
				viewController.edit_view.remove();
			}
			viewController.sub_log_view_controller = null;
			viewController.edit_view_ui_dic = {};
			viewController.edit_view_ui_validation_field_dic = {};
			viewController.edit_view_form_item_dic = {};
			viewController.edit_view_error_ui_dic = {};
			LocalCacheData.current_doing_context_action = '';
		}
	}
}

export class IndexViewController extends Backbone.View {
	constructor( options = {} ) {
		_.defaults( options, {
			el: 'body', //So we can add event listener for all elements
			router: null,
		} );

		super( options );
	}

	initialize( options ) {
		IndexViewController.instance = this;
		this.router = new ApplicationRouter();
		//Set title in index.php instead.
		//$( 'title' ).html( '' );
		this.router.controller = this;
		//Error: Backbone.history has already been started in interface/html5/framework/backbone/backbone-min.js?v=9.0.1-20151022-162110 line 28
		if ( !Backbone.History.started ) {
			Backbone.history.start();
		}
	}
}

IndexViewController.instance = null;