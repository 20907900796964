/* Tell js linting to ignore the webpack globals */
/*global __webpack_public_path__ */

// Modify the webpack config output.publicPath to take into account the extra directory level of quick_punch.
//__webpack_public_path__ = '../' + __webpack_public_path__;

// from main app
import * as StackTrace from 'stacktrace-js'
// import 'expose-loader?exposes=$,jQuery!jquery'; // Now done in webpack config -- Old: Needs be done with expose otherwise other imports error "jQuery/$ is not defined." e.g. plugins like i18n.
// import _ from 'underscore';
import 'backbone'; // does not seem to need expose loader.
import 'jquery-ui';
// end from main app

// global vendor imports
import 'popper.js';
import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import '@/framework/jquery.i18n.js';
import html2canvas from 'html2canvas';

// global imports
import { Global } from '@/global/Global';
import { LocalCacheData } from '@/global/LocalCacheData';
import { TTPromise } from '@/global/TTPromise'; // Potentially the same issue as ProgressBar (multiple instances, no single state), so lets just make it global to be safe.
import { ProgressBar } from '@/global/ProgressBarManager'; // Must only be imported in one place, otherwise multiple instances could be loaded and clash by leaving open Progressbars. e.g. Seen in TimeSheet
import { PermissionManager } from '@/global/PermissionManager';
import { TAlertManager } from '@/global/TAlertManager';

// imports for this file only
import { ServiceCaller } from '@/services/ServiceCaller';
import { TTAPI } from '@/services/TimeTrexClientAPI';
import { IndexViewController } from './IndexController'; // Make sure this is quick_punch/IndexController! Not from main UI.

window.$ = window.jQuery = $; // #3028 This is needed because although webpack will handle any references to $/jQuery in our code, its done internally and not on the window. So 3rd party libraries will not find jQuery on the window.
window.html2canvas = html2canvas;
window.StackTrace = StackTrace;
window.Global = Global;
window.LocalCacheData = LocalCacheData;
window.TTPromise = TTPromise;
window.ProgressBar = ProgressBar;
window.PermissionManager = PermissionManager;
window.TAlertManager = TAlertManager;
window.bootstrap = bootstrap;

window.is_browser_iOS = ( navigator.userAgent.match( /(iPad|iPhone|iPod)/g ) ? true : false );

var api_authentication = TTAPI.APIAuthentication;

if ( Error ) {
	Error.stackTraceLimit = 50; //Increase JS exception stack trace limit.
}

window.onerror = function( error_msg, file, line, col, error_obj ) {
	if ( !arguments || arguments.length < 1 ) {
		Global.sendErrorReport( 'No error parameters when window.onerror', ServiceCaller.root_url, '', '', '' );
	} else {
		Global.sendErrorReport( error_msg, file, line, col, error_obj );
	}
};

ServiceCaller.base_url = Global.getBaseURL( '../../../', false );
ServiceCaller.base_api_url = 'api/json/quick_punch/api.php';
ServiceCaller.root_url = Global.getRootURL();

var loginData = {};
//Set in APIGlobal.php
if ( !need_load_pre_login_data ) {
	loginData = APIGlobal.pre_login_data;
} else {
	need_load_pre_login_data = false;
}
if ( !loginData.hasOwnProperty( 'api_base_url' ) ) {
	api_authentication.getPreLoginData( null, {
		onResult: function( e ) {

			var result = e.getResult();

			LocalCacheData.setLoginData( result );
			APIGlobal.pre_login_data = result;

			loginData = LocalCacheData.getLoginData();
			initApps();

		}
	} );
} else {
	LocalCacheData.setLoginData( loginData ); //set here because the loginData is set from php
	initApps();
}
initAnalytics();

function initAnalytics() {
	/* jshint ignore:start */
	if ( APIGlobal.pre_login_data.analytics_enabled === true && ServiceCaller && ServiceCaller.root_url && loginData && loginData.base_url ) {
		if ( APIGlobal.pre_login_data.analytics_tracking_code != '' ) {
			try {
				var gtag_script = document.createElement( 'script' );
				gtag_script.setAttribute( 'src', 'https://www.googletagmanager.com/gtag/js?id=' + APIGlobal.pre_login_data.analytics_tracking_code );
				document.head.appendChild( gtag_script );

				window.dataLayer = window.dataLayer || [];
				window.gtag = function gtag() {
					window.dataLayer.push( arguments );
				}
				gtag( 'js', new Date() );
				gtag( 'config', APIGlobal.pre_login_data.analytics_tracking_code, {
					'debug_mode': !APIGlobal.pre_login_data.production,
					'send_page_view': false
				} );

				//Do not check existance of LocalCacheData with if(LocalCacheData) or JS will execute the unnamed function it uses as a constructor
				//Do not user LocalCacheData.getCurrentCompany for this comparison, or JS will throw a "cache is expired" error.
				if ( LocalCacheData.getPunchLoginUser() && LocalCacheData.getLocalCache( 'current_company', 'JSON' ) ) {
					var current_company = LocalCacheData.getCurrentCompany();
					Global.setAnalyticDimensions( LocalCacheData.getPunchLoginUser().first_name + ' (' + LocalCacheData.getPunchLoginUser().id + ')', current_company.name );
				} else {
					Global.setAnalyticDimensions();
				}
			} catch ( e ) {
				throw e; //Attempt to catch any errors thrown by Google Analytics.
			}
		}

		if ( APIGlobal.pre_login_data.ui_tracking_code != '' ) {
			try {
				var script = document.createElement( 'script' );
				script.type = 'text/javascript';
				script.async = true;
				script.innerHTML = `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "` + APIGlobal.pre_login_data.ui_tracking_code + `");`;
				document.head.appendChild( script );
			} catch ( e ) {
				throw e; //Attempt to catch any errors thrown.
			}
		}
	}
	/* jshint ignore:end */
}

function initApps() {
	loadViewRequiredJS();

	//Optimization: Only change locale if its *not* en_US or enable_default_language_translation = TRUE
	if ( loginData.locale !== 'en_US' || loginData.enable_default_language_translation == true ) {
		Global.loadLanguage( loginData.locale );
		Debug.Text( 'Using Locale: ' + loginData.locale, 'quick_punch/main.js', '', 'initApps', 10 );
	} else {
		LocalCacheData.setI18nDic( {} );
	}
	$.i18n.load( LocalCacheData.getI18nDic() );
	Global.initStaticStrings();

	LocalCacheData.deployment_on_demand = loginData.deployment_on_demand;
	LocalCacheData.productEditionId = loginData.product_edition;

	var controller = new IndexViewController(); //Even though controller variable is not used, this must be called.
}

function loadViewRequiredJS() {
	Global.url_offset = '../'; //Must be defined before any other JS/CSS is loaded, otherwise can cause 404 when changing languages.

	Global.addCss( 'global/widgets/talert/TAlert.css' );
	LocalCacheData.loadViewRequiredJSReady = true;
}