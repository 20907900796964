import { QuickPunchBaseViewController } from '@/views/quick_punch/QuickPunchBaseViewController';
import { TTAPI } from '@/services/TimeTrexClientAPI';
import { TTUUID } from '@/global/TTUUID';
import { TAlertManager } from '@/global/TAlertManager';
import { ServiceCaller } from '@/services/ServiceCaller';
import { Global } from '@/global/Global';
import { getFingerprint as ThumbmarkGetFingerprint, setOption as ThumbmarkSetOption } from '@thumbmarkjs/thumbmarkjs';

export class QuickPunchLoginViewController extends QuickPunchBaseViewController {
	constructor( options = {} ) {
		_.defaults( options, {
			events: {
				'change #language': 'onLanguageChange'
			},
			authentication_api: null,

		} );

		super( options );
	}

	initialize( options ) {
		super.initialize( options );

		var row = Global.loadWidget( 'views/quick_punch/login/QuickPunchLoginView.html' );
		this.template = _.template( row );
		this.setElement( this.template( {} ) );
		Global.contentContainer().html( this.$el );

		this.checkForWebkitTextSecuritySupport();
		this.api = TTAPI.APIPunch;
		this.authentication_api = TTAPI.APIAuthentication;
		this.currentUser_api = TTAPI.APIAuthentication;
		this.user_preference_api = TTAPI.APIUserPreference;
		this.date_api = TTAPI.APITTDate;
		this.permission_api = TTAPI.APIPermission;
		this.edit_view_error_ui_dic = {};
		LocalCacheData.setAllURLArgs( {} );
		this.render();
	}

	render() {
		var $this = this;
		this.login_btn = this.$( 'button#punch_login' );
		this.login_btn.on( 'click', function( e ) {
			//Issue #3319 - Quick Punch login would fail 30% of the time for a customer. Everytime this happened chrome would show cancelled requests in the network tab.

			// Potential Causes:
			// https://stackoverflow.com/questions/12009423/what-does-status-canceled-for-a-resource-mean-in-chrome-developer-tools
			// - The DOM element that caused the request to be made got deleted (i.e. an IMG is being loaded, but before the load happened, you deleted the IMG node)
			// - You did something that made loading the data unnecessary. (i.e. you started loading a iframe, then changed the src or overwrite the contents)
			// - There are lots of requests going to the same server, and a network problem on earlier requests showed that subsequent requests weren't going to work (DNS lookup error, earlier (same) request resulted e.g. HTTP 400 error code, etc)

			//Likely Cause:
			//Previouly we were not calling e.preventDefault(); on the click event, it was causing the form to submit at the same time of our click event which was causing the page to reload/interrupt another call and thus causing the request to be cancelled by the browser.
			e.preventDefault();
			e.stopPropagation();
			$this.onLogin( e );
		} );
		this.$( 'input[name="quick_punch_id"]' ).focus();
		this.setLanguageSourceData( 'language' );
		$( document ).off( 'keydown' ).on( 'keydown', function( event ) {
			var attrs = event.target.attributes;
			if ( event.keyCode === 13 ) {
				if ( $this.login_btn.attr( 'disabled' ) == 'disabled' ) {
					return;
				}
				$this.onLogin();
				event.preventDefault();
			}
			if ( event.keyCode === 9 && event.shiftKey ) {
				if ( attrs['tab-start'] ) {
					$this.$( 'button[tabindex=0]', $this.$el )[0].focus();
					event.preventDefault();
				}
			}
			if ( attrs['tab-end'] && event.shiftKey === false ) {
				$this.$( 'input[tabindex=1]', $this.$el )[0].focus();
				event.preventDefault();
			}
		} );
		return this;
	}

	// doLogin: function ( e ) {
	//     if ( e.keyCode === 13 ) {
	//         this.onLogin();
	//     }
	// },

	onLogin( e ) {
		var $this = this;
		var quick_punch_id = this.$( '#quick_punch_id' ).val();
		var quick_punch_password = this.$( '#quick_punch_password' ).val();
		this.login_btn.attr( 'disabled', 'disabled' );
		this.clearErrorTips( true, true );

		Global.refreshCSRFToken( function( e ) {
			if ( APIGlobal.pre_login_data.product_edition > 10 && APIGlobal.pre_login_data.product_edition_match == true ) {
				$this.authentication_api.PunchLogin( quick_punch_id, quick_punch_password, {
					onResult: function( raw_result ) {
						if ( raw_result && raw_result.isValid() ) {
							var result = raw_result.getResult();

							TTPromise.add( 'QPLogin', 'init' );

							TTPromise.add( 'QPLogin', 'CurrentStation' );
							$this.getCurrentStation();

							TTPromise.add( 'QPLogin', 'CurrentUser' );
							$this.getCurrentUser();

							// LocalCacheData.setSessionID( result.SessionID );
							setCookie( 'SessionID-QP', result.SessionID, {
								expires: 30,
								path: LocalCacheData.cookie_path
							} );

							TTPromise.add( 'QPLogin', 'Permissions' );
							$this.permission_api.getPermissions( {
								onResult: function( permissionRes ) {
									var permission_result = permissionRes.getResult();

									if ( permission_result != false ) {
										LocalCacheData.setPermissionData( permission_result );
										TTPromise.resolve( 'QPLogin', 'Permissions' );
									} else {
										//User does not have any permissions.
										Debug.Text( 'User does not have any permissions!', 'QuickPunchLoginController.js', 'QuickPunchViewController', 'getPermission', 10 );
										TAlertManager.showAlert( $.i18n._( 'Unable to login due to permissions, please try again and if the problem persists contact customer support.' ), '', function() {
											Global.Logout();
											window.location.reload();
										} );
									}
								}
							} );

							TTPromise.add( 'QPLogin', 'CurrentCompany' );
							$this.authentication_api.getCurrentCompany( {
								onResult: function( current_company_result ) {
									var com_result = current_company_result.getResult();

									if ( com_result != false ) {
										if ( com_result.is_setup_complete == 1 ) {
											com_result.is_setup_complete = true;
										} else {
											com_result.is_setup_complete = false;
										}

										LocalCacheData.setCurrentCompany( com_result );
										Debug.Text( 'Version: Client: ' + APIGlobal.pre_login_data.application_build + ' Server: ' + com_result.application_build, 'LoginViewController.js', 'LoginViewController', 'onUserPreference:next', 10 );

										if ( APIGlobal.pre_login_data.production == true && APIGlobal.pre_login_data.application_build != com_result.application_build ) {
											Debug.Text( 'Version mismatch on login: Reloading...', 'LoginViewController.js', 'LoginViewController', 'onUserPreference:next', 10 );
											window.location.reload( true );
										}

										TTPromise.resolve( 'QPLogin', 'CurrentCompany' );
									} else {
										Debug.Text( 'Unable to get company information!', 'QuickPunchLoginController.js', 'QuickPunchViewController', 'getPermission', 10 );
										TAlertManager.showAlert( $.i18n._( 'Unable to download required information, please check your network connection and try again. If the problem persists contact customer support.' ), '', function() {
											Global.Logout();
											window.location.reload();
										} );
									}
								}
							} );

							TTPromise.add( 'QPLogin', 'Locale' );
							$this.getLocale();

							//When all QPLogin promises complete, forward to punch view
							TTPromise.wait( 'QPLogin', null, function() {
								$this.goToView();
							} );

							TTPromise.resolve( 'QPLogin', 'init' );
						} else {
							$this.setErrorTips( raw_result );
						}
					}
				} );
			} else {
				Global.sendErrorReport( 'Product edition mismatch: ' + APIGlobal.pre_login_data.product_edition + ' Remote Version' + ': ' + APIGlobal.pre_login_data.application_build +' URL: '+ window.location.href, ServiceCaller.root_url, '', '', '' );
			}
		});
	}

	/* Checks if browser supports the use of -webkit-text-security, so we can obscure the password.
	 * We want type number so that mobile devices will trigger the numeric keypad rather than the alphabet keyboard.
	 * If not supported by browser (E.g. IE), replace element type number with type password.
	 * Note: type="number" is default in html, otherwise chrome on android does not change the keyboard to numeric keypad if other way around and converting to number from password. Seems to miss/ignore the type change.
	 */
	checkForWebkitTextSecuritySupport() {
		if ( typeof CSS !== 'function' || !CSS.supports( '-webkit-text-security', 'disc' ) ) {
			var old_elem = $( 'input#quick_punch_password' );
			var new_elem = old_elem.clone( true ); // clone all, including bound events
			new_elem.attr( 'type', 'password' );
			// Replace the element rather than simply changing the original, as IE does not support input type change well.
			old_elem.replaceWith( new_elem );
		}
	}

	getLocale() {
		var result = this.authentication_api.getLocale( $( 'select[name="language"]' ).val(), {
			onResult: function( result ) {
				var login_language = 'en_US';
				if ( result ) {
					login_language = result.getResult();
				}
				var message_id = TTUUID.generateUUID();
				if ( LocalCacheData.getLoginData().locale != null && login_language !== LocalCacheData.getLoginData().locale ) {
					ProgressBar.showProgressBar( message_id );
					ProgressBar.changeProgressBarMessage( $.i18n._( 'Language changed, reloading' ) + '...' );

					Global.setLanguageCookie( login_language );
					LocalCacheData.setI18nDic( null );
					setTimeout( function() {
						window.location.reload( true );
					}, 5000 );
				}
				TTPromise.resolve( 'QPLogin', 'Locale' );
			}
		} );
	}

	getCurrentUser() {
		this.currentUser_api.getCurrentUser( { onResult: this.onGetCurrentUser, delegate: this } );
	}

	getCurrentStation( callBack ) {
		var $this = this;

		var station_id = Global.getStationID();

		var api_station = TTAPI.APIStation;

		if ( station_id ) {
			api_station.getCurrentStation( station_id, '10', {
				onResult: function( result ) {
					doNext( result );
				}
			} );
		} else {
			//This is also done in QuickPunchLoginViewController.js and InOutViewController.js
			ThumbmarkSetOption( 'exclude', [ 'audio', 'canvas', 'webgl', 'system.browser.version' ] );
			ThumbmarkGetFingerprint( true ).then( ( browser_fingerprint ) => {
				Debug.Text( 'Browser Fingerprint: ' + browser_fingerprint.hash, 'InOutViewController.js', 'InOutViewController', 'getUserPunch', 10 );
				if ( browser_fingerprint && browser_fingerprint.hash != '' ) {
					station_id = 'BFP-'+ browser_fingerprint.hash;
				}

				api_station.getCurrentStation( station_id, '10', { //BFP = Browser FingerPrint prefix.
					onResult: function( result ) {
						doNext( result );
					}
				} );
			} ).catch( error => {
				api_station.getCurrentStation( '', '10', {
					onResult: function( result ) {
						doNext( result );
					}
				} );
			});
		}

		function doNext( result ) {
			var res_data = result.getResult();
			Global.setStationID( res_data ); //Set the station cookie immediately so it can be used in subsequent API calls.

			TTPromise.resolve( 'QPLogin', 'CurrentStation' );
		}
	}


	onGetCurrentUser( e ) {
		LocalCacheData.setPunchLoginUser( e.getResult() );
		TTPromise.resolve( 'QPLogin', 'CurrentUser' );
	}

	goToView() {
		this.doing_login = false;
		// TopMenuManager.ribbon_view_controller = null;
		// TopMenuManager.ribbon_menus = null;
		// Global.topContainer().empty();
		LocalCacheData.currentShownContextMenuName = null;

		//Ensure that the language chosen at the login screen is passed in so that the user's country can be appended to create a proper locale.IndexViewController.instance.router.removeCurrentView();
		// var target_view = getCookie( 'PreviousSessionType' );
		// if ( target_view && !getCookie( 'PreviousSessionID' ) ) {
		//     TopMenuManager.goToView( target_view );
		//     setCookie( 'PreviousSessionType', null,  30,
		//  LocalCacheData.cookie_path,
		//  Global.getHost()
		//  );
		// } else {
		//     if (Global.getDeepLink() != false){
		//         TopMenuManager.goToView(Global.getDeepLink());
		//     }else if ( LocalCacheData.getLoginUserPreference().default_login_screen ) {
		//         TopMenuManager.goToView( LocalCacheData.getLoginUserPreference().default_login_screen );
		//     } else {
		//         TopMenuManager.goToView( 'Home' );
		//     }
		// }

		// if ( !LocalCacheData.getCurrentCompany().is_setup_complete ) {
		//     IndexViewController.openWizard( 'QuickStartWizard' );
		// }
		Global.setURLToBrowser( Global.getBaseURL() + '#!m=QuickPunch' );
		var current_company = LocalCacheData.getCurrentCompany();
		if ( LocalCacheData && current_company ) {
			Global.setAnalyticDimensions( LocalCacheData.getPunchLoginUser().first_name + ' (' + LocalCacheData.getPunchLoginUser().id + ')', current_company.name );
		}
	}

	setErrorTips( result ) {
		this.clearErrorTips( true );
		var error_list = result.getDetails() ? result.getDetails()[0] : {};
		if ( error_list && error_list.hasOwnProperty( 'error' ) ) {
			error_list = error_list.error;
		}
		for ( var key in error_list ) {
			if ( !error_list.hasOwnProperty( key ) ) {
				continue;
			}
			var field_obj;
			if ( this.$( 'input[name="' + key + '"]' )[0] ) {
				field_obj = this.$( 'input[name="' + key + '"]' );
			} else if ( this.$( 'select[name="' + key + '"]' )[0] ) {
				field_obj = this.$( 'select[name="' + key + '"]' );
			}
			if ( field_obj ) {
				field_obj.addClass( 'is-invalid' );
				var errorString;
				if ( _.isArray( error_list[key] ) ) {
					errorString = error_list[key][0];
				} else {
					errorString = error_list[key];
				}
				field_obj.attr( 'data-bs-toggle', 'tooltip' );
				field_obj.attr( 'data-bs-placement', 'top' );
				field_obj.attr( 'title', errorString );
				var tooltip = new bootstrap.Tooltip( field_obj );
				tooltip.show();
				this.edit_view_error_ui_dic[key] = field_obj;
			}
		}
		if ( _.size( this.edit_view_error_ui_dic ) > 0 ) {
			this.login_btn.removeAttr( 'disabled' );
			_.min( this.edit_view_error_ui_dic, function( item ) {
				if ( item.attr( 'tabindex' ) ) {
					return parseInt( item.attr( 'tabindex' ) );
				}
			} ).focus();
		}
	}

	clearErrorTips( clear_all, destroy ) {
		for ( var key in this.edit_view_error_ui_dic ) {
			if ( this.edit_view_error_ui_dic[key].val() !== '' || clear_all ) {
				this.edit_view_error_ui_dic[key].removeClass( 'is-invalid' );
				this.edit_view_error_ui_dic[key].removeAttr( 'data-bs-toggle' );
				this.edit_view_error_ui_dic[key].removeAttr( 'data-bs-placement' );
				this.edit_view_error_ui_dic[key].removeAttr( 'data-bs-original-title' );
				this.edit_view_error_ui_dic[key].removeAttr( 'aria-label' );
				this.edit_view_error_ui_dic[key].removeAttr( 'title' );
			}

			if ( destroy ) {
				let tooltips = document.querySelectorAll( '.tooltip');
				for ( let i = 0; i < tooltips.length; i++ ) {
					tooltips[i].remove();
				}

				// This causes a JS exception on Chrome on Linux when validation error appears twice. (Click Login button twice)
				// Uncaught TypeError: Cannot convert undefined or null to object
				// var tooltip = bootstrap.Tooltip.getInstance( this.edit_view_error_ui_dic[key] );
				// if ( tooltip ) {
				// 	tooltip.dispose();
				// }
			}
		}
	}

	setLanguageSourceData( field, source_data, set_empty ) {
		var $this = this;
		var field_selector = 'select[name="' + field + '"]';
		if ( this.$( field_selector ) && this.$( field_selector )[0] ) {
			this.$( field_selector ).empty();
		} else {
			return;
		}
		if ( !source_data ) {
			source_data = LocalCacheData.getLoginData().language_options;
			source_data = Global.removeSortPrefixFromArray( ( LocalCacheData.getLoginData().language_options ) );
		}
		if ( _.size( source_data ) == 0 ) {
			set_empty = true;
		}
		if ( set_empty === true ) {
			this.$( field_selector ).append( $( '<option></option>' ).prop( 'value', '0' ).text( '-- ' + $.i18n._( 'None' ) + ' --' ) ).attr( 'selected', 'selected' );
		}
		if ( _.size( source_data ) > 0 ) {
			$.each( source_data, function( value, label ) {
				$this.$( field_selector ).append( $( '<option></option>' ).attr( 'value', value ).text( label ) );
				if ( LocalCacheData.getLoginData().language == value ) {
					$this.$( field_selector ).val( value );
				}
			} );
		}
		// $this.$( field_selector ).selectpicker();
	}

	onLanguageChange( e ) {
		Global.setLanguageCookie( $( e.target ).val() );
		LocalCacheData.setI18nDic( null );
		var message_id = TTUUID.generateUUID();
		ProgressBar.showProgressBar( message_id );
		ProgressBar.changeProgressBarMessage( $.i18n._( 'Language changed, reloading' ) + '...' );

		setTimeout( function() {
			window.location.reload( true );
		}, 2000 );
	}
}


